import React, { CSSProperties } from "react";
import "./Project.css"

interface ProjectP {
    key: number,
    project: any
}

export default class Project extends React.Component<ProjectP> {
    render() {
        const backgroundStyle: CSSProperties = {
            backgroundImage: `url("/projects/${this.props.project.image}")`
        }
        return (
            <div className="Project" style={backgroundStyle}>
                <div>
                    <span>{this.props.project.name}</span>
                </div>
            </div>
        )
    }
}