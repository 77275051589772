import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./Animations.css"
import "./App.css"

import Home from "./pages/Home";
import Error from "./pages/Error";
import Portfolio from "./pages/Portfolio";
import Navigation from "./components/Navigation";
import LogoBar from "./components/LogoBar";

export default class App extends React.Component {
  render() {
    return (      
      <div className="App fade-in">
       <BrowserRouter>
            <Switch>
              <Route path="/" component={Home} exact/>
              <Route path="/portfolio" component={Portfolio}/>
              <Route component={Error}/>
            </Switch>
            <Navigation />
        </BrowserRouter>
        <LogoBar />
      </div> 
    );
  }
}