import React from "react";
import ReactMarkdown from "react-markdown";
import "../../Animations.css"
import "../page.css"
import "./Portfolio.css"

import Project from "./Project";

interface PortfolioS {
    markdown: string,
    projectArray: Array<JSX.Element>
}

export default class Portfolio extends React.Component<any, PortfolioS> {
    constructor(props: any) {
        super(props);
        this.state = {
            markdown: "",
            projectArray: new Array<JSX.Element>()
        };
    }

    async componentDidMount() {
        const markdown = await (await fetch(require("./Portfolio.md"))).text();
        const projects: Array<any> = require("./Portfolio.json");
        const projectComponentArray = projects.map((project, index): JSX.Element  => {
            return <Project key={index} project={project} />;
        });
        requestAnimationFrame(() => {
            this.setState({
                ...this.state,
                markdown: markdown,
                projectArray: projectComponentArray
            });
        });
    }

    render() {
        return (
            <div className="Portfolio page fade-in">
                <ReactMarkdown source={this.state.markdown} />
                <div className="ProjectContainer">
                    {this.state.projectArray}
                </div>
            </div>
        )
    }
}