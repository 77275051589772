import React from "react";
import ReactMarkdown from 'react-markdown';
import "../../Animations.css";
import "../page.css";

interface ErrorS {
    markdown: string
}

export default class Error extends React.Component<any,ErrorS> {
    constructor(props: any) {
        super(props);
        this.state = {
            markdown: ""
        };
    }

    async componentDidMount() {
        const markdown = await (await fetch(require("./Error.md"))).text();
        requestAnimationFrame(() => {
            this.setState({
                ...this.state,
                markdown: markdown
            });
        });
    }

    render() {
        return (
            <div className="Home page fade-in">
                <ReactMarkdown source={this.state.markdown} />
            </div>
        )
    }
}