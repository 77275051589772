import React from "react";
import { ReactComponent as Logo } from "./bentec.svg"
import "./LogoBar.css"

export default class LogoBar extends React.Component {
    render() {
        return (
            <div className="LogoBar">
                <Logo />
            </div>
        )
    }
}