import React from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css"

export default class Navigation extends React.Component {
    render() {
        return (
            <div className="Navigation">
                <NavLink to="/" activeClassName="chosen" exact>Home</NavLink>
                <NavLink to="/Portfolio" activeClassName="chosen">Portfolio</NavLink>
            </div>
        )
    }
}